import {Debbie} from 'components/organisms/Debbie';
import {StyledRadioGroup} from 'features/setup/components/StyledRadioGroup';
import {goBack, navigate as navigateTo} from 'lib/navigation/routes';
import React, {useEffect, useMemo, useState} from 'react';
import {getBillerSlugFromUrl} from 'lib/url';
import {InstalmentPlanMode} from 'lib/graphql/API';
import {DatePicker} from 'features/setup/shared/DatePicker';
import {useSetupSearchParams} from '../hooks/useSetupSearchParams';
import {useSetupRoute} from '../components/SetupRoute';
import {usePatchSearchParams} from '../../../lib/navigation/usePatchSearchParams';
import {BeautifiedErrorMessage} from 'components/organisms/BeautifiedErrormessage';
import {useSetupNavigate} from '../hooks/useSetupNavigate';
import {AbsoluteDate, getBillerConfig} from 'payble-shared';

type PaymentFrequency = 'weekly' | 'fortnightly' | 'monthly';

const FREQUENCY_OPTIONS = [
  {
    id: 'plan-frequency-weekly',
    value: 'WEEKLY',
    label: 'Weekly',
    description:
      'Pay an amount weekly to catch up and get on top of your rates',
  },
  {
    id: 'plan-frequency-fortnightly',
    value: 'FORTNIGHTLY',
    label: 'Fortnightly',
    description:
      'Pay an amount fortnightly to catch up and get on top of your rates',
  },
  {
    id: 'plan-frequency-monthly',
    value: 'MONTHLY',
    label: 'Monthly',
    description:
      'Pay an amount monthly to catch up and get on top of your rates',
  },
];
export const BalancedPayFrequency = () => {
  const [mode, setMode] = useState(FREQUENCY_OPTIONS[0].value);
  const {account} = useSetupRoute();
  const billerSlug = getBillerSlugFromUrl();
  const billerConfig = getBillerConfig(billerSlug);
  const {instalmentStartAt} = useSetupSearchParams();
  const {patch} = usePatchSearchParams();
  const navigate = useSetupNavigate();
  const [maxDate, setMaxDate] = useState(() =>
    AbsoluteDate.today({billerConfig}).plus({days: 30})
  );
  const minDate = useMemo(() => AbsoluteDate.today({billerConfig}), []);
  const [date, setDate] = useState(instalmentStartAt);

  const [dateError, setDateError] = useState<Error>();
  useEffect(() => {
    if (account.targetInstalments) {
      const {
        targetInstalments: [first],
      } = account;
      if (!first) {
        return;
      }
      setMaxDate(first.dueAt);
    }
  }, [account.targetInstalments]);

  if (!date) {
    return (
      <BeautifiedErrorMessage
        debbieTitle="Oops, something went wrong!!"
        debbieMessage="Missing start date. Please try again."
        onClick={() => {
          navigateTo('/biller/:slug/setup', {slug: billerSlug});
        }}
      />
    );
  }

  return (
    <div className="relative w-full h-full px-4 py-6 mx-auto sm:px-6 lg:py-16 lg:px-8">
      <div className="relative flex flex-col h-full">
        <Debbie title="How often would you like to make payments?" />

        <div className="max-w-sm px-4 mx-auto mt-4 space-y-2 sm:px-6">
          <h4 className="font-semibold text-center">
            Choose when payments will start
          </h4>

          <DatePicker
            error={dateError}
            setError={setDateError}
            minDate={minDate}
            maxDate={maxDate}
            onChange={date => {
              patch({instalmentStartAt: date.toISO()});
              setDate(date);
            }}
            value={date}
          />
        </div>

        <div className="flex flex-col items-center justify-center flex-1 h-full">
          <StyledRadioGroup
            value={mode}
            options={FREQUENCY_OPTIONS}
            onChange={e => {
              setMode(e);
              patch({instalmentStartAt: date.toISO()});
            }}
          />
          <button
            type="button"
            className="inline-flex items-center justify-center w-full px-6 py-3 mt-4 text-base font-medium text-center text-white transition bg-blue-600 border border-transparent rounded-md shadow-sm disabled:opacity-50 disabled:cursor-not-allowed hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
            disabled={!!dateError}
            onClick={() => {
              navigate('/biller/:slug/setup/plan/preview', {
                instalmentMode: InstalmentPlanMode.BalancedPayEveryX,
                instalmentFrequency: mode.toLowerCase() as PaymentFrequency,
              });
            }}
          >
            Next
          </button>
          <button
            onClick={goBack}
            className="mt-6 text-blue-600 transition hover:text-blue-700"
          >
            Back
          </button>
        </div>
      </div>
    </div>
  );
};
