import React, {useEffect, useState} from 'react';
import {goBack} from 'lib/navigation/routes';
import {Debbie} from 'components/organisms/Debbie';
import {StyledRadioGroup} from 'features/setup/components/StyledRadioGroup';
import {InstalmentPlanMode} from 'lib/graphql/API';
import {err} from 'payble-shared';
import {ErrorMessage} from 'components/atoms/ErrorMessage';
import {useBillerConfig} from 'lib/appConfig/useBillerConfig';
import {useSetupSearchParams} from '../hooks/useSetupSearchParams';
import {useSetupNavigate} from '../hooks/useSetupNavigate';

const OPTIONS = [
  {
    value: 'automatic',
    label: 'Pay in 4 instalments each year',
    description:
      'Pay in 4 instalments each year. You will receive reminder notifications before each payment is processed.',
  },
  {
    value: 'annually',
    label: 'Pay once each year',
    description:
      'Pay once each year the full amount due. You will receive a reminder notification before each payment is processed.',
  },
];

export const AutoPayOptions = () => {
  const [frequency, setFrequency] = useState(OPTIONS[0].value);
  const billerConfig = useBillerConfig();
  const {accountType} = useSetupSearchParams();
  const navigate = useSetupNavigate();
  const autoPayConfig = billerConfig.getAutopayConfig(accountType);

  if (err(autoPayConfig)) {
    return (
      <ErrorMessage
        message={'An error as occurred. Please contact Payble support.'}
        showReturnHome
      />
    );
  }

  /**
   * Remove this block when we got chance to test the annual flow and updated the E2E tests.
   *
   *  PR that should fix this: https://github.com/payble-payments/payble/pull/1265
   *
   */
  //if (!autoPayConfig.annually) {
  useEffect(() => {
    navigate('/biller/:slug/setup/plan/preview', {
      instalmentMode: InstalmentPlanMode.PayXOnY,
      instalmentFrequency: 'automatic',
    });
  }, []);
  //}

  return (
    <>
      <div className="relative w-full h-full px-4 py-6 mx-auto sm:px-6 lg:py-16 lg:px-8">
        <div className="relative flex flex-col h-full">
          <Debbie title="When do you want to make your Autopayments?" />
          <div className="flex flex-col items-center justify-center flex-1 h-full">
            <StyledRadioGroup
              value={frequency}
              options={OPTIONS}
              onChange={e => setFrequency(e as 'automatic' | 'annually')}
            />
            <button
              type="button"
              className="inline-flex items-center justify-center w-full px-6 py-3 mt-4 text-base font-medium text-center text-white transition bg-blue-600 border border-transparent rounded-md shadow-sm disabled:opacity-50 disabled:cursor-not-allowed hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
              onClick={() => {
                navigate('/biller/:slug/setup/plan/preview', {
                  instalmentFrequency: frequency,
                  instalmentMode: InstalmentPlanMode.PayXOnY,
                });
              }}
            >
              Next
            </button>
            <button
              onClick={goBack}
              className="mt-6 text-blue-600 transition hover:text-blue-700"
            >
              Back
            </button>
          </div>
        </div>
      </div>
    </>
  );
};
