import React, {ReactChild} from 'react';
import {useState, useEffect} from 'react';
import {StyledSwitchGroup} from 'features/setup/components/StyledSwitchGroup';
import {
  useBillerConfig,
  usePaymentSurchargePercent,
} from 'lib/appConfig/useBillerConfig';
import * as Sentry from '@sentry/react';
import {TermsModal} from 'components/molecules/TermsModal';
import {PaymentMethodType} from '../../lib/graphql/API';
import {toPaymentMethodType} from '../../lib/types/DirectDebit';

type Props = {
  biller: {
    name: string;
    slug: string;
  };
  paymentMethodType: PaymentMethodType;
  callback: (isChecked: boolean) => void;
  disabled?: boolean;
};

type Toggles = Record<string, boolean>;

export const PaymentMethodAgreement = ({
  biller,
  paymentMethodType,
  callback,
  disabled,
}: Props) => {
  const [termsModalOpen, setTermsModalOpen] = useState(false);

  const toggles = usePaymentMethodAgreementToggles(
    biller,
    paymentMethodType,
    setTermsModalOpen,
    termsModalOpen
  );

  const [toggleStates, setToggleStates] = useState<Toggles>(
    toggles.reduce(
      (acc, {name, checked, children}) => ({...acc, children, [name]: checked}),
      {}
    )
  );

  const onToggle = (name: keyof Toggles) => {
    setToggleStates(prev => ({
      ...prev,
      [name]: !prev[name],
    }));
  };

  useEffect(() => {
    callback(Object.values(toggleStates).every(Boolean));
  }, [toggleStates]);

  return (
    <>
      {toggles.map(({name, children}) => (
        <StyledSwitchGroup
          key={name}
          checked={toggleStates[name]}
          onChange={() => onToggle(name)}
          testId={name}
          disabled={disabled}
        >
          {children}
        </StyledSwitchGroup>
      ))}
    </>
  );
};

function usePaymentMethodAgreementToggles(
  biller: {
    name: string;
    slug: string;
  },
  paymentMethodType: PaymentMethodType,
  setTermsModalOpen: (isOpen: boolean) => void,
  termsModalOpen: boolean
): {
  name: string;
  checked: boolean;
  children: ReactChild;
}[] {
  const billerConfig = useBillerConfig();
  const becsUserId = billerConfig.directDebitUserId;

  useEffect(() => {
    if (!becsUserId && billerConfig.paymentMethods.includes('direct_debit')) {
      Sentry.captureException(
        `${biller.name} is missing a direct debit user ID.`
      );
    }
  }, [becsUserId]);

  const surcharge = usePaymentSurchargePercent(
    toPaymentMethodType(paymentMethodType)
  );

  const toggles = [
    {
      name: 'privacy-policy-agreement',
      checked: false,
      children: (
        <>
          I/we agree to Payble's{' '}
          <a
            href="https://payble.com.au/terms-privacy/"
            className="text-blue-600 underline"
            target="_blank"
          >
            Terms and Conditions and Privacy Policy
          </a>
        </>
      ),
    },
  ];

  if (paymentMethodType === 'directDebit') {
    toggles.push({
      name: 'becs-service-agreement',
      checked: false,
      children: (
        <>
          I/we authorise and request, {biller.name} (User ID {becsUserId}) to
          debit the account provided through the Bulk Electronic Clearing System
          (BECS) in accordance with the{' '}
          <a
            href={billerConfig.support?.directDebitServiceAgreement}
            className="text-blue-600 underline"
            target="_blank"
          >
            Direct Debit Request Service Agreement
          </a>
        </>
      ),
    });
  }
  if (biller.slug === 'cessnock-city-council') {
    if (paymentMethodType === 'directDebit') {
      toggles.push({
        name: 'information-consent-agreement',
        checked: false,
        children: (
          <>
            I/we acknowledge I have read, understand and consent for my
            information to be collected, used, disclosed or otherwise handled as
            outlined above. I acknowledge the information I provided is
            truthful, accurate and complete
          </>
        ),
      });
    }
    toggles.push({
      name: 'information-collection-agreement',
      checked: false,
      children: (
        <>
          I/we consent for my information to be collected, used, disclosed or
          otherwise handled by Council for the purpose of:
          <ul>
            <li>
              sharing information with me concerning Council services,
              functions, operations and events;
            </li>
            <li>
              contacting me to seek voluntary completion of surveys that relate
              to Council’s services, functions and operations.
            </li>
          </ul>
        </>
      ),
    });
  }

  if (billerConfig.billerSlug === 'energy-on') {
    toggles.push({
      checked: false,
      name: 'confirm-dd-surcharge',
      children: (
        <>
          I understand that dishonour fees may apply if my direct debit payment
          fails.
        </>
      ),
    });
  }

  if (
    paymentMethodType === 'directDebit' &&
    billerConfig.requiresNominatedAccountAgreement
  ) {
    toggles.push({
      checked: false,
      name: 'confirm-nominated-account-agreement',
      children: (
        <p>
          I confirm the nominated bank account is solely in my name, and is not
          a joint account or business account.{' '}
          {!!billerConfig.nominatedAccountAgreementTerms && (
            <>
              <a
                className="text-blue-600 underline cursor-pointer"
                onClick={() => setTermsModalOpen(true)}
              >
                Why?
              </a>
              <TermsModal
                isOpen={termsModalOpen}
                setOpen={setTermsModalOpen}
                terms={billerConfig.nominatedAccountAgreementTerms}
              />
            </>
          )}
        </p>
      ),
    });
  }
  if (surcharge) {
    toggles.push({
      checked: false,
      name: 'surcharge-agreement',
      children: (
        <p>
          I understand a <b>surcharge of {surcharge}</b> applies to this and all
          future
          {paymentMethodType === PaymentMethodType.Card
            ? ' card'
            : ' direct debit'}{' '}
          payments
        </p>
      ),
    });
  }

  if (paymentMethodType === 'nzDirectDebit') {
    toggles.push({
      checked: false,
      name: 'nz-dd-agreement',
      children: (
        <>
          <p>By indicating here, I</p>
          <ul className="list-disc pl-4">
            <li>
              confirm I can operate and have sole authority on the nominated
              account number
            </li>
            <li>
              confirm that I have read and accept the{' '}
              <a
                href={`${billerConfig.support.directDebitServiceAgreement}`}
                className="text-blue-500 underline"
              >
                direct debit terms and conditions
              </a>
            </li>
            <li>
              Confirm that the direct debit is also subject to my banks’
              standard terms and conditions that relate to my account
            </li>
            <li>
              agree to receive confirmation of the sign up to direct debits
              (within 5 business <span className="underline">days</span>) and{' '}
              <span className="underline">notifications</span> for ongoing
              payments to the email address provided
            </li>
          </ul>
          <p>
            Should two signatures be required please download a{' '}
            <a
              href={`${billerConfig.support.directDebitServicePaperForm}`}
              className="text-blue-500 underline"
            >
              paper form
            </a>{' '}
            and send us the signed form.
          </p>
          <p>
            For any questions please contact us on {billerConfig.support.phone}{' '}
            or email us at{' '}
            <a
              href={`mailto:${billerConfig.support?.email}`}
              className="text-blue-500 underline"
            >
              {billerConfig.support?.email}
            </a>
            .
          </p>
        </>
      ),
    });
  }

  return toggles;
}
