import DOMPurify from 'dompurify';
import snarkdown from 'snarkdown';

export function markDownToHtml(
  markdown: string,
  allowedTags: string[]
): string {
  return DOMPurify.sanitize(snarkdown(markdown), {
    ALLOWED_TAGS: allowedTags,
  });
}
