import React, {useContext, useEffect} from 'react';

import {Routes, Route} from 'react-router-dom';

import {useActor} from '@xstate/react';

import * as Auth from 'lib/auth/Auth';
import {
  getSearchParamValue,
  history,
  navigate,
  RouteName,
  RouteProps,
} from 'lib/navigation/routes';

import {Navbar} from 'features/navigation/Navbar';
import {Payment} from 'features/payment/Payment';
import {InstalmentPlan} from 'features/instalment-plan/InstalmentPlan';
import {Setup as SetupV3} from 'features/setup/Setup';
import {Infringement} from 'features/infringement/Infringement';
import {Login} from 'features/login/Login';
import {Footer} from 'features/navigation/Footer';
import {NotFound} from 'features/navigation/NotFound';
import {Loading} from 'features/navigation/Loading';
import {CustomRouter} from 'lib/navigation/CustomerRouter';
import {Confirmation} from 'features/biller/pages/confirmation/Confirmation';

import {getBillerSlugFromUrl} from 'lib/url';
import {Home} from 'features/home/Home';
import {Profile} from 'features/profile/Profile';
import {AddPaymentMethod} from 'features/profile/pages/AddPaymentMethod';
import {EditProfile} from 'features/profile/pages/EditProfile';
import {RedirectToHome} from './RedirectToHome';
import {VerifyEmail} from 'features/profile/pages/VerifyEmail';
import {ErrorBoundary} from 'features/navigation/ErrorBoundary';
import {Support} from 'features/support/Support';

const ROUTES: RouteProps[] = [
  {
    element: <SetupV3 />,
    path: '/biller/:slug/setup/*',
  },
  {
    element: <Infringement />,
    path: '/biller/:slug/infringements/*',
  },
  {
    element: <Support />,
    path: '/biller/:slug/support',
  },
  {
    element: <InstalmentPlan />,
    path: '/biller/:slug/instalment-plan/:instalmentPlanId/*',
  },
  {
    element: <Profile />,
    path: '/biller/:slug/profile/*',
  },
  {
    element: <EditProfile />,
    path: '/biller/:slug/profile/edit',
  },
  {
    element: <VerifyEmail />,
    path: '/biller/:slug/verify-email',
  },
  {
    element: <AddPaymentMethod />,
    path: '/biller/:slug/profile/add-payment-method',
  },
  {
    element: <Confirmation />,
    path: '/biller/:slug/confirmation',
  },
  {
    element: <Home />,
    path: '/biller/:slug',
  },
  {
    element: <Payment />,
    path: '/biller/:slug/payment/:paymentId',
  },
];

export const Navigation: React.FC = () => {
  // When we are deploying large changes we need to create an outage
  // window so there are no changes made from under us, in the most
  // basic form we do a deployment which locks out users until we redeploy
  // without the below.
  // return (
  //   <div className="flex flex-col justify-between min-h-screen bg-gray-100">
  //     <Navbar></Navbar>
  //     <div className="flex-1 h-full contents">
  //       <Maintenance />
  //     </div>
  //     <Footer></Footer>
  //   </div>
  // );

  // If we land here using a domain which has the biller in we
  // can then direct routes using the biller slug, if we landed
  // using the slug already that will be returned.
  const slug = getBillerSlugFromUrl();

  const {authService} = useContext(Auth.Context);
  const [authState] = useActor(authService);
  const [hasRedirected, setHasRedirected] = React.useState(false);
  const goTo = getSearchParamValue('goTo');

  useEffect(() => {
    if (authState.matches({authenticated: 'idle'})) {
      if (authState.context.session) {
        if (!hasRedirected) {
          switch (authState.context.session.type) {
            case 'empty':
              break;
            case 'v1':
              navigate('/biller/:slug/setup', {slug: slug}, true);
              break;
            case 'legacy':
              // Energy on...
              break;
          }

          setHasRedirected(true);
        }

        if (goTo) {
          navigate(goTo as RouteName, {}, true);
        }
      }
    }
  }, [authState.value]);

  // This is quite important. It moves known domains for example 'payble.kingston.vic.gov.au'
  // to use the URL scheme of the SPA. It's not a long term option but is needed to make the
  // QR codes and magic link logins work.
  if (window.location.pathname.indexOf('/biller/') === -1 && slug) {
    navigate('/biller/:slug', {slug: `${slug}${window.location.search}`}, true);
  }

  if (
    window.location.pathname.indexOf('/undefined') >= 0 ||
    slug === undefined
  ) {
    navigate('/not-found', {}, true);
  }

  if (
    authState.matches('initialising') ||
    authState.matches('authenticating')
  ) {
    return <Loading />;
  }

  if (authState.matches({authenticated: 'refreshing'}) && !!goTo) {
    return <Loading />;
  }
  if (
    !authState.matches({authenticated: 'idle'}) &&
    !authState.matches({authenticated: 'refreshing'}) &&
    window.location.pathname.indexOf('/infringements/') === -1 &&
    window.location.pathname.indexOf('verify-email') === -1
  ) {
    return (
      <CustomRouter history={history}>
        <Routes>
          <Route
            path="/biller/:slug/support"
            element={
              <div className="flex flex-col justify-between min-h-screen bg-gray-100">
                <Navbar></Navbar>
                <div className="flex-1 h-full contents">
                  <Support />
                </div>
                <Footer></Footer>
              </div>
            }
          />
          <Route path="/biller/:slug" element={<Login />} />
          <Route path="/biller/:slug/login" element={<Login />} />
          <Route
            path="*"
            element={
              <div className="flex flex-col justify-between min-h-screen bg-gray-100">
                <Navbar></Navbar>
                <div className="flex-1 h-full contents">
                  <NotFound />
                </div>
                <Footer></Footer>
              </div>
            }
          />
        </Routes>
      </CustomRouter>
    );
  }

  return (
    <ErrorBoundary>
      <div className="flex flex-col justify-between min-h-screen bg-gray-100">
        <Navbar />
        <div className="flex-1 h-full contents">
          <CustomRouter history={history}>
            <Routes>
              <Route path="/biller/:slug/login" element={<RedirectToHome />} />
              {ROUTES.map(route => (
                <Route
                  key={route.path}
                  path={route.path}
                  element={route.element}
                />
              ))}
              <Route path="*" element={<NotFound />} />
            </Routes>
          </CustomRouter>
        </div>
        <Footer />
      </div>
    </ErrorBoundary>
  );
};
