import {z} from 'zod';
import {isDomainName} from '../../util/text';
import {FormConfig} from '../types';

export const transact = {
  title: 'TRANSACT Gateway',

  validation: z.object({
    hostname: z.string().refine(isDomainName, {
      message: 'Invalid hostname',
    }),
    merchant: z.string().min(1, 'Merchant is required'),
    password: z.string().min(1, 'Password is required'),
  }),

  form: [
    {
      type: 'text',
      name: 'hostname',
      label: 'Hostname',
    },
    {
      type: 'text',
      name: 'merchant',
      label: 'Merchant',
    },
    {
      type: 'text',
      name: 'password',
      label: 'Password',
    },
  ],
} satisfies FormConfig;
