import {Result} from '.';

export function validatePhoneNumber(phoneNumber: string | null) {
  const numbers = phoneNumber?.match(/\d/g) || [];
  if (numbers.length < 7) {
    return new Error(`Phone number '${phoneNumber}' is invalid`);
  }

  return phoneNumber;
}

//https://stackoverflow.com/questions/46155/whats-the-best-way-to-validate-an-email-address-in-javascript
export function validateEmail(email: string): Result<string> {
  const match = String(email)
    .toLowerCase()
    .trim()
    .match(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    );

  if (!match) {
    return new Error(`Email '${email}' is not valid`);
  }

  return email.toLowerCase().trim();
}

export function validateGivenName(givenName: string): Result<string> {
  if (givenName.trim().length < 1) {
    return new Error(`Given name '${givenName}' is too short`);
  }

  return givenName.trim();
}

export function validateFamilyName(familyName: string): Result<string> {
  if (familyName.trim().length < 2) {
    return new Error(`Family name '${familyName}' is too short`);
  }

  return familyName.trim();
}

export const validateBankAccountNumber = (accountNumber: string) =>
  /^\d+$/.test(accountNumber) &&
  accountNumber.length >= 3 &&
  accountNumber.length <= 9;

export const ACCOUNT_NAME_MAX_LENGTH = 32;

export const validateBankAccountName = (accountName: string) =>
  accountName.length <= ACCOUNT_NAME_MAX_LENGTH;

export const validateDirectDebitReference = (reference: string) =>
  reference.length <= 18;
