import {Debbie} from 'components/organisms/Debbie';
import {useGetAccountFromSearchParams} from 'lib/account/useGetAccountFromSearchParams';
import {Loading} from 'components/atoms/Loading';
import {ErrorMessage} from 'components/atoms/ErrorMessage';
import {formatToDollars} from 'payble-shared';

import NumberFormat from 'react-number-format';
import {goBack} from 'lib/navigation/routes';
import {useInfringementNavigate} from '../hooks/useInfringementNavigate';
import {BeautifiedErrorMessage} from 'components/organisms/BeautifiedErrormessage';

export const ConfirmAccountPayment = () => {
  const {
    loading,
    data: account,
    error,
  } = useGetAccountFromSearchParams({anonymous: true});
  const infringementNavigate = useInfringementNavigate();

  if ((loading || !account) && !error) return <Loading />;

  if (error) {
    return (
      <BeautifiedErrorMessage
        debbieTitle="Oops, something went wrong!!"
        debbieMessage={error}
        onClick={() => {
          infringementNavigate('/');
        }}
      />
    );
  }

  return (
    <div className="relative h-full max-w-2xl px-4 py-6 mx-auto sm:px-6 lg:max-w-7xl lg:py-8 lg:px-8">
      <div className="relative flex flex-col h-full gap-4">
        <Debbie title="Here’s your infringement information" />
        <div className="mt-4 overflow-hidden rounded-md shadow">
          <div className="px-4 py-4 bg-white sm:p-6">
            <div className="border-b">
              <div className="grid grid-cols-2 gap-4 pb-4">
                <div>
                  <h4 className="mb-2 font-medium text-gray-900">
                    Infringement number
                  </h4>
                  <p className="text-sm text-gray-600">{account.externalId}</p>
                </div>
                <div>
                  <h4 className="mb-2 font-medium text-gray-900">
                    Infringement description
                  </h4>
                  <p className="block text-sm text-gray-600 break-words">
                    {account.description}
                  </p>
                </div>
              </div>
            </div>

            <div className="grid grid-cols-2 gap-4 pt-4 d">
              <div>
                <h4 className="mb-2 font-medium text-gray-900">
                  Total balance
                </h4>
                <p className="text-sm text-gray-600">
                  <NumberFormat
                    value={formatToDollars(account.amountOwing)}
                    displayType={'text'}
                    decimalSeparator="."
                    decimalScale={2}
                    fixedDecimalScale={true}
                    thousandSeparator={true}
                    prefix={'$'}
                  />
                </p>
              </div>

              {account?.targetDate && (
                <div>
                  <h4 className="mb-2 font-medium text-gray-900">Due at</h4>
                  <p className="text-sm text-gray-600">
                    {account?.targetDate?.toFormat('dd MMM yyyy')}
                  </p>
                </div>
              )}
            </div>
          </div>
        </div>

        {error ? <ErrorMessage message={error} /> : null}

        <button
          onClick={() => {
            infringementNavigate('/pay-now-features');
          }}
          className="inline-flex items-center justify-center w-full px-6 py-3 mt-4 text-base font-medium text-center text-white transition bg-blue-600 border border-transparent rounded-md shadow-sm disabled:opacity-50 disabled:cursor-not-allowed hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
        >
          Pay total now
        </button>
        <button
          className="inline-flex items-center justify-center w-full px-6 py-3 mt-4 text-base font-medium text-center text-white transition bg-blue-600 border border-transparent rounded-md shadow-sm disabled:opacity-50 disabled:cursor-not-allowed hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
          onClick={() => {
            infringementNavigate('/flexible-features');
          }}
        >
          Create flexible payment plan
        </button>
        <a
          target="_blank"
          className="text-center text-gray-500 cursor-pointer hover:underline"
          href="https://www.greaterdandenong.vic.gov.au/parking-infringements"
        >
          Something not right or need to request a review?
        </a>
        <button
          type="button"
          className="mt-6 text-blue-600 transition hover:text-blue-700"
          onClick={goBack}
        >
          Back
        </button>
      </div>
    </div>
  );
};
