import {classNames} from 'lib/styles';
import React from 'react';

import cardAmex from 'assets/card/amex-small.svg';
import cardEmpty from 'assets/card/empty.svg';
import cardError from 'assets/card/error-small.svg';
import cardMastercard from 'assets/card/mastercard-small.svg';
import cardVisa from 'assets/card/visa-small.svg';
import {
  TOKENEX_CARD_ID,
  TokenExCreditCardFormRef,
  useTokenexIframe,
} from 'lib/tokenex/useTokenexIframe';
import {Loading} from 'components/atoms/Loading';
import {ErrorMessage} from 'components/atoms/ErrorMessage';
import {GetAuthKeyQuery} from '../../lib/graphql/API';
import {useGetAuthKey} from 'lib/auth/useGetAuthKey';
import {CardType} from 'payble-shared';

export type TokenExCreditCardFormProps = {
  billerSlug: string;
  anonymous?: boolean;
  onBlur?: () => void;
  onValidate?: (data: any) => void;
  onError?: (data: any) => void;
};

const cardImage: {
  [key in CardType | 'error']: string;
} = {
  unknown: cardEmpty,
  jcb: cardEmpty,
  diners: cardEmpty,
  americanExpress: cardAmex,
  masterCard: cardMastercard,
  visa: cardVisa,
  error: cardError,
};

export const TokenExCreditCardInternal = React.forwardRef<
  TokenExCreditCardFormRef,
  TokenExCreditCardFormProps & {
    data: Omit<GetAuthKeyQuery['authKey'], '__typename'>;
    refetchAuthKey: () => void;
  }
>((props, ref) => {
  const {onBlur, onValidate, onError, data, refetchAuthKey} = props;

  const {loaded, focused, error, cardType, containerRef} = useTokenexIframe({
    data,
    refetchAuthKey,
    onBlur,
    onValidate,
    onError,
    ref,
  });

  const focusOnCardNumber = () =>
    (ref as React.RefObject<TokenExCreditCardFormRef>)?.current?.focus();

  return (
    <div className="flex-1" onClick={focusOnCardNumber}>
      <label
        htmlFor="card-number"
        className="block text-sm font-bold text-navy"
      >
        Card number
      </label>
      <div
        className={classNames(
          'relative h-[2.35rem] w-full overflow-hidden transition-all duration-300 ease-out pt-1.5',
          'border mt-1 shadow-sm text-sm border-gray-300 rounded-md placeholder-gray-400',
          focused ? 'border-blue-600 ring-1 ring-blue-600' : '',
          error ? 'border-red-600 ring-1 ring-red-600' : ''
        )}
      >
        <div
          id={TOKENEX_CARD_ID}
          ref={containerRef}
          className={classNames(
            'w-full absolute top-[0.45rem] left-0 bottom-[0.375rem] px-1 md:px-3 transition-all duration-300 ease-out',
            loaded ? 'opacity-100' : 'opacity-0'
          )}
        ></div>
        <img
          className={classNames(
            'hidden sm:block h-6 w-10 flex-none object-contain absolute opacity-0 transition-opacity duration-300 ease-out right-2',
            cardType !== 'unknown' || error ? 'opacity-100' : 'opacity-0'
          )}
          src={cardImage[error ? 'error' : cardType]}
        />
      </div>
    </div>
  );
});

export const TokenExCreditCardNumber = React.forwardRef<
  TokenExCreditCardFormRef,
  TokenExCreditCardFormProps
>((props, ref) => {
  const {data, loading, error, refetch} = useGetAuthKey({
    anonymous: props.anonymous,
  });

  if (!data || loading) return <Loading />;
  if (error) return <ErrorMessage message={error && error.message} />;

  return (
    <TokenExCreditCardInternal
      {...props}
      data={data}
      refetchAuthKey={refetch}
      ref={ref}
    />
  );
});
