import React from 'react';
import {toTitleCase} from 'payble-shared';
import {CreatedInstalmentPlan} from '../graphql/actions';

export const ConfirmationPaymentFrequencySection = ({
  mode,
  instalmentPlan,
}: {
  mode: string;
  instalmentPlan?: CreatedInstalmentPlan;
}) => {
  if (mode === 'PAY_AMOUNT') {
    return (
      <>
        <p className="text-sm text-gray-600">Payment frequency</p>
        <p className="text-sm text-gray-600">One-off payment</p>
      </>
    );
  }
  if (mode === 'PAY_AUTO') {
    return (
      <>
        <p className="text-sm text-gray-600">Payment frequency</p>
        <p className="text-sm text-gray-600">Recurring on due date</p>
      </>
    );
  }
  if (mode === 'PAY_FLEXIBLE' && instalmentPlan?.frequency) {
    return (
      <>
        <p className="text-sm text-gray-600">Payment frequency</p>
        <p className="text-sm text-gray-600">
          {toTitleCase(instalmentPlan?.frequency)}
        </p>
      </>
    );
  }

  if (mode === 'PAY_EVERY_X' && instalmentPlan?.frequency) {
    return (
      <>
        <p className="text-sm text-gray-600">Payment frequency</p>
        <p className="text-sm text-gray-600">
          {toTitleCase(instalmentPlan.frequency)}
        </p>
      </>
    );
  }

  return null;
};
