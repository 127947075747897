import React, {useEffect, useState} from 'react';
import {CheckIcon, XMarkIcon} from '@heroicons/react/20/solid';

import {NZDirectDebitValues} from 'lib/types/DirectDebit';
import {classNames} from 'lib/styles';
import {NZBankAccountNumber} from 'payble-shared';
import {useGetBankByCodeLazyQuery} from 'lib/graphql/API';
import {useDebouncedCallback} from 'use-debounce';
import {MiniSpinner} from 'components/atoms/Spinner';
import {DirectDebitAccountNumberInputNZ} from 'components/molecules/DirectDebitAccountNumberInputNZ';

type Props = {
  disabled: boolean;
  onChange: (value: NZDirectDebitValues) => void;
  onValid: (value: boolean) => void;
  value: NZDirectDebitValues;
};

export const DirectDebitFormNZ: React.FC<Props> = ({
  disabled,
  onChange,
  onValid,
  value,
}) => {
  const [getBankByCode, {data, loading, called, error}] =
    useGetBankByCodeLazyQuery();
  const [accountName, setAccountName] = useState<string>(value.accountName);
  const [accountNameError, setAccountNameError] = useState<string | undefined>(
    undefined
  );
  const bank = data?.bankByCode;

  const validateBank = useDebouncedCallback(
    async (nzBankAccountNumber: NZBankAccountNumber) => {
      const code = nzBankAccountNumber.toBankBranchCode();
      await getBankByCode({
        variables: {
          code,
          region: 'nz',
        },
      });
    },
    500
  );

  const [accountNumber, setAccountNumber] = useState<string>('');
  const [accountNumberError, setAccountNumberError] = useState<
    string | undefined
  >(undefined);

  const hasValidBankBranchCode = !!(called && !loading && bank);

  useEffect(() => {
    onChange({
      accountName,
      accountNumber,
    });
    onValid(
      !accountNumberError &&
        !accountNameError &&
        !!accountNumber &&
        !!accountName &&
        hasValidBankBranchCode
    );
  }, [
    accountNumberError,
    accountNameError,
    accountNumber,
    accountName,
    hasValidBankBranchCode,
  ]);

  const onAccountNameChange = (value: string) => {
    const accountName = value.trim();
    setAccountName(accountName);
    if (
      !accountName ||
      accountName.length > 20 ||
      !accountName.match(/^[a-zA-Z0-9\s]+$/)
    ) {
      setAccountNameError('Invalid account name');
    } else {
      setAccountNameError(undefined);
    }
  };

  const onAccountNumberChange = (value: string) => {
    const accountNumber = value.trim();
    setAccountNumber(accountNumber);

    const nzBankAccountNumber =
      NZBankAccountNumber.maybeFromJSON(accountNumber);
    if (nzBankAccountNumber) {
      setAccountNumberError(undefined);
      validateBank(nzBankAccountNumber);
    } else {
      setAccountNumberError('Invalid account number');
    }
  };

  return (
    <>
      <div className="relative col-span-6 space-y-4">
        <label
          htmlFor="accountName"
          className="block text-sm font-bold text-navy"
        >
          Account Name
        </label>
        <input
          type="text"
          name="accountName"
          id="accountName"
          disabled={disabled}
          defaultValue={accountName}
          onChange={e => onAccountNameChange(e.target.value)}
          className={classNames(
            'mt-1 focus:ring-blue-600 focus:border-blue-600 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md',
            !accountNameError ? 'border-blue-600' : 'border-red-600'
          )}
        />
        {accountNameError && (
          <span className="mt-1 text-sm text-red-600">{accountNameError}</span>
        )}

        <label
          htmlFor="accountNumber"
          className="block text-sm font-bold text-navy"
        >
          Account number
        </label>
        <div className="pb-2">
          <DirectDebitAccountNumberInputNZ
            onChange={value => {
              onAccountNumberChange(value);
            }}
          />
          <div className="absolute !mt-1">
            {loading && <MiniSpinner />}
            {accountNumber && accountNumberError ? (
              <div className="flex items-center gap-1">
                <XMarkIcon className="w-4 h-4 text-red-500" />
                <span className="text-sm text-gray-400">
                  {accountNumberError}
                </span>
              </div>
            ) : called && !loading ? (
              bank ? (
                <div className="flex items-center gap-1">
                  <CheckIcon className="w-4 h-4 text-green-500" />
                  <span className="text-sm text-gray-400">{bank?.name}</span>
                </div>
              ) : (
                <div className="flex items-center gap-1">
                  <XMarkIcon className="w-4 h-4 text-red-500" />
                  <span className="text-sm text-gray-400">
                    {error ? error.message : 'Unknown bank branch code'}
                  </span>
                </div>
              )
            ) : null}
          </div>
        </div>
      </div>
    </>
  );
};
