import React, {useEffect} from 'react';
import {RouteName} from 'lib/navigation/routes';
import {Route, RouteProps, Routes, useLocation} from 'react-router-dom';
import {LookupAccount} from './pages/LookupAccount';
import {ConfirmAccountPayment} from './pages/ConfirmAccountPayment';
import {ApolloProvider} from '@apollo/client';
import {publicClient} from 'lib/graphql/client';
import {PayNow} from './pages/PayNow';
import {PayNowFeatures} from './pages/PayNowFeatures';
import {FlexibleFeatures} from './pages/FlexibleFeatures';

const BASE_ROUTE: RouteName = '/biller/:slug/infringements';

export const ROUTES: RouteProps[] = [
  {
    path: `${BASE_ROUTE}/:type`,
    element: <LookupAccount />,
  },
  {
    path: `${BASE_ROUTE}/:type/confirm-payment`,
    element: <ConfirmAccountPayment />,
  },
  {
    path: `${BASE_ROUTE}/:type/pay-now`,
    element: <PayNow />,
  },
  {
    path: `${BASE_ROUTE}/:type/pay-now-features`,
    element: <PayNowFeatures />,
  },
  {
    path: `${BASE_ROUTE}/:type/flexible-features`,
    element: <FlexibleFeatures />,
  },
];

export const Infringement = () => {
  const location = useLocation();
  const pathname = location.pathname;

  useEffect(() => {
    document.title = 'Payble - Infringement';
  }, [pathname]);

  return (
    <div className="flex flex-col justify-start flex-1 w-full h-full overflow-hidden lg:py-8">
      <div className="z-10 flex flex-1 h-full max-w-xl m-auto md:w-xl">
        <ApolloProvider client={publicClient}>
          <Routes>
            {ROUTES.map(({path, element}) => {
              const relativePath = path?.slice(BASE_ROUTE.length + 1);
              return (
                <Route
                  key={relativePath}
                  element={element}
                  path={relativePath}
                />
              );
            })}
          </Routes>
        </ApolloProvider>
      </div>
    </div>
  );
};
