import React from 'react';
import {PaymentMethod, PaymentMethodStatus} from 'lib/graphql/API';
import {getImageAndAltText} from 'lib/logos';
import NumberFormat from 'react-number-format';
import {formatLast4} from 'payble-shared';

export const ConfirmationPaymentMethodSection = ({
  paymentMethod,
}: {
  paymentMethod?: PaymentMethod;
}) => {
  if (!paymentMethod) {
    return null;
  }

  const {image} = getImageAndAltText(paymentMethod);

  return (
    <>
      {paymentMethod.type === 'card' && paymentMethod.card && (
        <label
          htmlFor={`account-${paymentMethod.id}`}
          id={`account-${paymentMethod.id}-description`}
          className="text-sm text-gray-500"
        >
          <div className="flex">
            <div className="flex-none">
              <div className="font-mono">
                {formatLast4(paymentMethod.card.last4)}
              </div>
              <div>
                {paymentMethod.card.expiryMonth} /{' '}
                {paymentMethod.card.expiryYear}
              </div>
            </div>

            <img className="object-contain ml-4 h-14 w-14" src={image} />
          </div>
        </label>
      )}

      {paymentMethod.type === 'directDebit' && paymentMethod.bank && (
        <label
          htmlFor={`account-${paymentMethod.id}`}
          id={`account-${paymentMethod.id}-description`}
          className="text-sm text-gray-500"
        >
          <div className="flex">
            <div className="flex-none">
              <div className="font-mono">
                <NumberFormat
                  value={paymentMethod.bank.bsb}
                  displayType={'text'}
                  format="###-###"
                />
              </div>
              <div>{paymentMethod.bank.accountNumber}</div>
            </div>

            <div
              className="flex-1 h-10 bg-center bg-no-repeat bg-contain w-14"
              style={{backgroundImage: `url(${image})`}}
            ></div>
          </div>
        </label>
      )}

      {paymentMethod.status === PaymentMethodStatus.Disabled && (
        <div className="italic text-red-600">
          Payment method disabled due to "{paymentMethod.reason}"
        </div>
      )}
    </>
  );
};
