import {z} from 'zod';
import {isDomainName} from '../../util/text';
import {FormConfig} from '../types';

export const mastercard_gateway = {
  title: 'Mastercard Gateway',

  validation: z.object({
    hostname: z.string().refine(isDomainName, {
      message: 'Invalid hostname',
    }),
    merchantId: z.string().min(1, 'Merchant id is required'),
    merchantAccessCode: z.string().min(1, 'Merchant access code is required'),
  }),

  form: [
    {
      type: 'text',
      name: 'hostname',
      label: 'Hostname',
    },
    {
      type: 'text',
      name: 'merchantId',
      label: 'Merchant Id',
    },
    {
      type: 'text',
      name: 'merchantAccessCode',
      label: 'Merchant Access Code',
    },
  ],
} satisfies FormConfig;
