import {Decimal} from 'decimal.js';
import {ChargeableAmount} from '../util/SurchargeCalculator';

/**
 * Represents a percentage value between the values of "99.99999%" and
 * "0.00000%" with a maximum of 5 decimal places.
 *
 *
 * Percentage should be persisted as a NUMERIC(7, 5) assuming a maximum of 5
 * decimal places
 */
export class SurchargeRate {
  private constructor(private base10: number) {
    if (typeof base10 !== 'number') {
      throw new Error(
        `Percentage must be a number. Got ${typeof base10}: ${base10} instead.`
      );
    }
    if (Number.isNaN(base10)) {
      throw new Error('Percentage cannot be NaN');
    }
    if (base10 >= 100) {
      throw new Error('Percentage can only represent values below 100%');
    }
    if (this.decimal.decimalPlaces() > 5) {
      throw new Error(
        'Percentage can only represent values with up to 5 decimal places'
      );
    }
  }

  /**
   * A decimal number representing a percentage, e.g. 10 for 10%
   */
  static fromJSON(decimal: number): SurchargeRate {
    return new SurchargeRate(decimal);
  }

  /**
   * A decimal number representing a percentage, e.g. 0.1 for 10%
   */
  static fromLegacyDecimal(decimal: number) {
    return new SurchargeRate(new Decimal(decimal).times(100).toNumber());
  }

  static fromPretty(pretty: string): SurchargeRate {
    const match = pretty.match(/^\d+(\.\d+)?%$/);
    if (!match) {
      throw new Error(`Invalid percentage: ${pretty}`);
    }
    return new SurchargeRate(new Decimal(parseFloat(pretty)).toNumber());
  }

  calculateSurcharge(principal: number): ChargeableAmount {
    const surchargeNumber = this.multipliable
      .times(principal)
      .toDecimalPlaces(0)
      .toNumber();

    return {
      total: principal + surchargeNumber,
      originalAmount: principal,
      surcharge: {
        surchargedAmount: surchargeNumber,
        rate: this.multipliable.toNumber(),
        transactRate: this.toTransactFormat(),
      },
    };
  }

  equals(other: SurchargeRate) {
    return this.decimal.eq(other.decimal);
  }

  private get decimal() {
    return new Decimal(this.base10);
  }

  private get multipliable() {
    return this.decimal.dividedBy(100);
  }

  private toTransactFormat(): string {
    return this.multipliable.toFixed(5).slice(2).padEnd(6, '0');
  }

  toPretty() {
    return `${parseFloat(this.decimal.toFixed(5))}%`;
  }

  toJSON() {
    return this.decimal.toNumber();
  }
}
