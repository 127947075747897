import 'react-datepicker/dist/react-datepicker.css';
import {classNames} from 'lib/styles';
import React from 'react';
import {AbsoluteDate} from 'payble-shared';
interface Props {
  minDate: AbsoluteDate;
  maxDate: AbsoluteDate;

  error?: Error;
  setError: (error?: Error) => void;

  value: AbsoluteDate;
  onChange: (date: AbsoluteDate) => void;
}
export const DatePicker = (props: Props) => {
  const {onChange, minDate, maxDate, value, setError, error} = props;

  const onSetStartDate = (date: AbsoluteDate | null) => {
    setError(undefined);

    if (date === null) {
      setError(new Error('Start date is required'));
      return;
    }
    if (date.isBefore(minDate)) {
      setError(
        new Error(
          `Start date cannot be before ${minDate.toFormat('dd/MM/yyyy')}`
        )
      );
      return;
    }
    if (date.isAfter(maxDate)) {
      setError(
        new Error(
          `Start date cannot be after ${maxDate.toFormat('dd/MM/yyyy')}`
        )
      );
      return;
    }

    onChange(date);
  };

  return (
    <div>
      <input
        id="startDate"
        name="startDate"
        type="date"
        min={minDate.toISO()}
        max={maxDate.toISO()}
        className="bg-white rounded-md shadow-sm  hover:border-blue-500 border-1 w-full text-center transition border-transparent"
        defaultValue={value.toISO()}
        onChange={e =>
          onSetStartDate(AbsoluteDate.maybeFromISO(e.target.value))
        }
      />
      <div
        className={classNames(
          'mt-2 text-sm text-red-600 text-center transition',
          error ? 'opacity-100' : 'opacity-0'
        )}
      >
        {error ? error.message : ''}
      </div>
    </div>
  );
};
