import {z} from 'zod';
import {intFromString} from '../../util/text';
import {FormConfig} from '../types';

export const utilibill = {
  title: 'UtiliBill Integration',

  validation: z.object({
    username: z.string().min(1),
    password: z.string().min(1),
    apiBaseURL: z.string().url(),
    maxGroupNumber: z
      .string()
      .transform(intFromString)
      .pipe(z.number().positive())
      .or(z.number().positive()),
  }),

  form: [
    {
      label: 'API Base URL',
      name: 'apiBaseURL',
      type: 'text',
      placeholder: 'https://billeruat.utilibill.com.au/biller',
      description:
        'Typically looks like "https://billeruat.utilibill.com.au/biller"',
    },
    {
      label: 'Username/Client ID',
      name: 'username',
      type: 'text',
      placeholder: 'Payble',
    },
    {
      label: 'Password/Client Secret',
      name: 'password',
      type: 'password',
      placeholder: 'Password',
    },
    {
      label: 'Max Group Number',
      name: 'maxGroupNumber',
      type: 'number',
      placeholder: '150',
      description:
        'There is no way to detect the number of "customer groups" in utilibill programatically. This is the maximum number of groups we will search for customers in. If customers do not show up after sync increase this number.',
    },
  ],
} satisfies FormConfig;
