import React from 'react';
import {getBillerSlugFromUrl} from 'lib/url';
import {goBack} from 'lib/navigation/routes';
import {PaymentSchedule} from 'features/setup/shared/PaymentSchedule';
import NumberFormat from 'react-number-format';
import {formatToDollars} from 'payble-shared';
import {useSetupSearchParams} from '../hooks/useSetupSearchParams';
import {BeautifiedErrorMessage} from 'components/organisms/BeautifiedErrormessage';
import {useSetupNavigate} from '../hooks/useSetupNavigate';
import {getFeatureConfig} from 'payble-shared/src/biller-config/handlers';

export const OneOffSchedule = () => {
  const billerSlug = getBillerSlugFromUrl();
  const {instalmentStartAt, amountInCents} = useSetupSearchParams();
  const navigate = useSetupNavigate();

  if (!instalmentStartAt) {
    return (
      <BeautifiedErrorMessage
        debbieTitle="Oops, something went wrong!!"
        debbieMessage="Missing start date. Please try again."
        onClick={() => {
          navigate('/biller/:slug/setup');
        }}
      />
    );
  }

  return (
    <PaymentSchedule
      startAt={instalmentStartAt}
      debbieTitle={
        amountInCents
          ? `When do you want to make the payment of $${formatToDollars(
              amountInCents
            )}`
          : 'When do you want to make your first payment?'
      }
      description={
        <span>
          For your one-off payment of{' '}
          <NumberFormat
            value={formatToDollars(amountInCents)}
            displayType={'text'}
            thousandSeparator={true}
            decimalSeparator={'.'}
            fixedDecimalScale={true}
            decimalScale={2}
            prefix={'$'}
          />
        </span>
      }
      onClickNext={targetDate => {
        navigate('/biller/:slug/setup/payment-method', {
          paymentTargetDate: targetDate.toISO(),
          mode: 'PAY_ON_Y',
        });
      }}
      onClickBack={goBack}
      scheduleMaxDays={getFeatureConfig(billerSlug).ONE_OFF_SCHEDULED_DAYS}
    />
  );
};
