import React from 'react';

import NumberFormat from 'react-number-format';
import {Account} from 'lib/graphql/API';
import {ErrorMessage} from 'components/atoms/ErrorMessage';
import {Loading} from 'features/navigation/Loading';
import {formatToDollars} from 'payble-shared';
import {useBillerConfig} from 'lib/appConfig/useBillerConfig';

const PreviewTargetInstalments: React.FC<{
  targetInstalments: {label: string; amount: number}[];
  headline: string;
  hideDueDate?: boolean;
}> = ({targetInstalments, headline, hideDueDate}) => {
  return (
    <>
      <div className="grid grid-cols-2 gap-4 pt-4 pb-2">
        <h4 className="font-medium text-gray-900">{headline}</h4>
        {!hideDueDate && (
          <h4 className="font-medium text-gray-900">Due date</h4>
        )}
      </div>

      <div className="grid grid-cols-2 gap-x-4 gap-y-2">
        {targetInstalments.map(instalment => (
          <>
            <p
              className="text-sm text-gray-600"
              key={`${instalment.amount}-${instalment.label}`}
            >
              <NumberFormat
                value={formatToDollars(instalment.amount)}
                displayType={'text'}
                decimalSeparator="."
                decimalScale={2}
                fixedDecimalScale={true}
                thousandSeparator={true}
                prefix={'$'}
              />
            </p>

            <p className="text-sm text-gray-600">{instalment.label}</p>
          </>
        ))}
      </div>
    </>
  );
};

type ConfirmLinkAccountProps = {
  account: Account;
  onLinkAccount: () => Promise<void>;
  onLookupAnotherAccount: () => void;
};

export const ConfirmLinkAccount: React.FC<ConfirmLinkAccountProps> = ({
  account,
  onLinkAccount,
  onLookupAnotherAccount,
}) => {
  const billerConfig = useBillerConfig();

  if (!account) return <Loading />;

  const setupBreakdown = account?.setupBreakdown ?? [];

  const targetInstalments = account?.targetInstalments
    ? account.targetInstalments.map(instalment => ({
        amount: instalment.amount,
        label: instalment.dueAt.toFormat('dd MMM yyyy'),
      }))
    : [];

  const isHamiltonCityCouncil =
    billerConfig.billerSlug === 'hamilton-city-council';

  return (
    <>
      <div className="mt-4 overflow-hidden rounded-md shadow">
        <div className="px-4 py-4 bg-white sm:p-6">
          <div className="border-b">
            <div className="grid grid-cols-2 gap-4 pb-4">
              <div className="">
                <h4 className="mb-2 font-medium text-gray-900">
                  {billerConfig.accountCategoryLabel}
                </h4>
                <p className="text-sm text-gray-600">
                  {billerConfig.getAccountTypeTitle(account.type)}
                </p>
              </div>

              <div className="">
                <h4 className="mb-2 font-medium text-gray-900">
                  {billerConfig.getExternalIdLabel(account.type)}
                </h4>
                <p className="text-sm text-gray-600">{account.externalId}</p>
              </div>
            </div>
          </div>

          <div
            className={`grid ${isHamiltonCityCouncil ? 'grid-cols-1' : 'grid-cols-2'} gap-4 py-4 border-b`}
          >
            <div>
              <h4 className="mb-2 font-medium text-gray-900">Address</h4>
              <p className="block text-sm text-gray-600 break-words">
                {account.description}
              </p>
            </div>
            {!isHamiltonCityCouncil && (
              <div>
                <h4 className="mb-2 font-medium text-gray-900">
                  Account status
                </h4>
                {account.amountOwing > 0 ? (
                  <span className="inline-flex px-4 py-2 text-xs leading-5 text-white rounded-full bg-amber-300">
                    Payment due
                  </span>
                ) : (
                  <span className="inline-flex px-4 py-2 text-xs leading-5 text-white bg-green-300 rounded-full">
                    On track
                  </span>
                )}
              </div>
            )}
          </div>

          {setupBreakdown.length ? (
            <PreviewTargetInstalments
              headline="Outstanding amounts"
              targetInstalments={setupBreakdown}
              hideDueDate
            />
          ) : null}

          {targetInstalments.length ? (
            <PreviewTargetInstalments
              headline="Instalments"
              targetInstalments={targetInstalments}
            />
          ) : null}

          {billerConfig.calculatedInstalments ? (
            <div className="pt-4 pb-2">
              <p className="text-sm text-gray-600">
                These payments are calculated based on your current balance.
                There may be some variances from the annual rates notice issued.
              </p>
            </div>
          ) : null}

          <div className="grid grid-cols-2 gap-4 pt-4 pb-2">
            <h4 className="font-medium text-gray-900">Total balance</h4>
          </div>
          <div className="grid grid-cols-2 gap-x-4 gap-y-2">
            <p className="text-sm text-gray-600">
              <NumberFormat
                value={formatToDollars(account.amountOwing)}
                displayType={'text'}
                decimalSeparator="."
                decimalScale={2}
                fixedDecimalScale={true}
                thousandSeparator={true}
                prefix={'$'}
              />
            </p>
          </div>
        </div>
      </div>

      {account?.collectionExemption ? (
        <ErrorMessage
          message={`Unfortunately we can't register your account at this time, please contact ${billerConfig.supportEmail} for more information.`}
          className="px-0"
        />
      ) : (
        <button
          onClick={onLinkAccount}
          className="inline-flex items-center justify-center w-full px-6 py-3 mt-4 text-base font-medium text-center text-white bg-blue-600 border border-transparent rounded-md shadow-sm disabled:opacity-50 disabled:cursor-not-allowed hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
        >
          Let's go!
        </button>
      )}

      <button
        className="mt-6 text-blue-600 transition hover:text-blue-700"
        onClick={() => onLookupAnotherAccount()}
      >
        Look up a different property
      </button>
    </>
  );
};
