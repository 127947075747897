export const HowToVideoData = [
  {
    title: 'Login to Payble',
    cloudfareStreamVideoID: '996fa78401ba1d91204038d33cb00a7d',
    videoLink:
      'https://iframe.cloudflarestream.com/996fa78401ba1d91204038d33cb00a7d?preload=metadata',
    htmlId: 'login',
  },
  {
    title: 'Setting up a flexible payment plan',
    cloudfareStreamVideoID: 'a5b0271dbca2769a3dcd5cf1f16e7e45',
    videoLink:
      'https://iframe.cloudflarestream.com/a5b0271dbca2769a3dcd5cf1f16e7e45?preload=metadata',
    htmlId: 'setup-plan',
  },
  {
    title: 'Manage your payment plan',
    cloudfareStreamVideoID: '6d15bfc6f725da1d5a3dac6e746b8c43',
    videoLink:
      'https://iframe.cloudflarestream.com/6d15bfc6f725da1d5a3dac6e746b8c43?preload=metadata',
    htmlId: 'manage-plan',
  },
  {
    title: 'Skip a scheduled payment',
    cloudfareStreamVideoID: 'dcdf55d53849636d6c60d2e70682ba77',
    videoLink:
      'https://iframe.cloudflarestream.com/dcdf55d53849636d6c60d2e70682ba77?preload=metadata',
    htmlId: 'skip-instalment',
  },
  {
    title: 'Cancelling an payment plan',
    cloudfareStreamVideoID: '615c03d75262a8989ea8d22b364ac62f',
    videoLink:
      'https://iframe.cloudflarestream.com/615c03d75262a8989ea8d22b364ac62f?preload=metadata',
    htmlId: 'cancel-plan',
  },
];
