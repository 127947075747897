import {z} from 'zod';
import {FormConfig} from '../types';

export const payway = {
  title: 'Payway',

  validation: z.object({
    bankId: z.string().min(1, 'Bank ID is required'),
    merchantId: z.string().min(1, 'Merchant ID is required'),
    publishableKey: z.string().min(1, 'Publishable key is required'),
    secretKey: z.string().min(1, 'Secret key is required'),
    test: z.boolean().optional(),
  }),

  form: [
    {
      type: 'text',
      name: 'bankId',
      label: 'Bank ID',
    },
    {
      type: 'text',
      name: 'merchantId',
      label: 'Merchant ID',
    },
    {
      type: 'text',
      name: 'publishableKey',
      label: 'Publishable key',
    },
    {
      type: 'text',
      name: 'secretKey',
      label: 'Secret key',
    },
  ],
} satisfies FormConfig;
