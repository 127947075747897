import {PaymentMethodType} from 'payble-shared';
import {useContext} from 'react';
import {Context as BillerConfigContext} from './BillerConfig';

export const useBillerConfig = () => {
  const config = useContext(BillerConfigContext);
  if (!config) {
    throw new Error('No biller config');
  }

  return config;
};

export const usePaymentSurchargePercent = (type: PaymentMethodType) => {
  return useBillerConfig().surchargeCalculator.prettySurchargePercent(type);
};
