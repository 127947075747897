import {BillerConfig} from 'payble-shared';

export function getArrangementPath(billerConfig: BillerConfig) {
  if (billerConfig.hasCatchUpPlans) {
    return '/biller/:slug/setup/catch-up';
  }
  if (billerConfig.hasRequestPlan) {
    return '/biller/:slug/setup/request-plan';
  }

  return null;
}
