import {DateTime} from 'luxon';
import {BillerConfigSchema} from '../schema';

type IsPayEveryXUnavailable = {
  billerConfig: BillerConfigSchema;
  now?: DateTime;
};

export function isPayEveryXUnavailable({
  billerConfig,
  now = DateTime.now(),
}: IsPayEveryXUnavailable): boolean {
  if (!billerConfig?.payEveryX) {
    return false;
  }

  const startPeriod = DateTime.fromISO(
    billerConfig.payEveryX.unavailablePeriod.start,
    {zone: billerConfig.timezone}
  );

  const endPeriod = DateTime.fromISO(
    billerConfig.payEveryX.unavailablePeriod.end,
    {
      zone: billerConfig.timezone,
    }
  );

  now.setZone(billerConfig.timezone);

  return now >= startPeriod && now <= endPeriod;
}
