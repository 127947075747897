import {Debbie} from 'components/organisms/Debbie';
import * as Sentry from '@sentry/react';
import {StyledRadioGroup} from 'features/setup/components/StyledRadioGroup';
import {navigate as navigateTo} from 'lib/navigation/routes';
import React, {useEffect, useMemo, useState} from 'react';
import {getBillerSlugFromUrl} from 'lib/url';
import {
  InstalmentPlanMode,
  InstalmentType,
  usePreviewInstalmentPlanLazyQuery,
} from 'lib/graphql/API';
import {DatePicker} from 'features/setup/shared/DatePicker';
import {useSetupSearchParams} from '../hooks/useSetupSearchParams';
import {useSetupRoute} from '../components/SetupRoute';
import {usePatchSearchParams} from '../../../lib/navigation/usePatchSearchParams';
import {BeautifiedErrorMessage} from 'components/organisms/BeautifiedErrormessage';
import {useSetupNavigate} from '../hooks/useSetupNavigate';
import {getPlans} from 'lib/getPlans';
import {AbsoluteDate, formatToDollars} from 'payble-shared';
import {
  FREQUENCY_OPTIONS,
  getSmoothOptionsCopy,
} from '../helpers/getSmoothOptionsCopy';
import {useBillerConfig} from 'lib/appConfig/useBillerConfig';

type PaymentFrequency = 'weekly' | 'fortnightly' | 'monthly';

export const SmoothPayFrequency = () => {
  const {account} = useSetupRoute();
  const billerSlug = getBillerSlugFromUrl();
  const billerConfig = useBillerConfig();
  const {instalmentStartAt, instalmentFrequency, amountInCents} =
    useSetupSearchParams();
  const [mode, setMode] = useState<PaymentFrequency>(
    instalmentFrequency as PaymentFrequency
  );
  const {patch} = usePatchSearchParams();
  const navigate = useSetupNavigate();
  const [maxDate, setMaxDate] = useState(() =>
    AbsoluteDate.today({billerConfig}).plus({days: 30})
  );
  const minDate = useMemo(() => AbsoluteDate.today({billerConfig}), []);
  const hasSetup = !!account.setupBreakdown?.length;
  const hasInstalments = !!account.targetInstalments?.length;
  const [date, setDate] = useState(
    hasSetup
      ? AbsoluteDate.today({billerConfig}).plus({weeks: 2})
      : instalmentStartAt
  );
  const {averageWeeklyPayment} = account.meta;
  const [dateError, setDateError] = useState<Error>();

  useEffect(() => {
    if (account.targetInstalments) {
      const {
        targetInstalments: [first],
      } = account;
      if (!first) {
        return;
      }
      setMaxDate(first.dueAt);
    }
  }, [account.targetInstalments]);

  const [getPlanPreview, {data, error, loading}] =
    usePreviewInstalmentPlanLazyQuery({
      onError: error => {
        Sentry.captureException(
          new Error(
            `Could not load SmoothPay instalment preview: biller: ${
              billerSlug ?? 'slug missing'
            } ${error.message}`
          )
        );
      },
    });

  type FetchPlans = {
    startDate?: AbsoluteDate | null;
    offPeriodInstalmentAmount?: number | null;
  };

  const fetchPlans = async ({startDate = null}: FetchPlans) => {
    if (!instalmentStartAt) {
      return null;
    }

    getPlans({
      startDate: startDate ?? instalmentStartAt,
      accountId: account.id,
      getPlanPreview,
      payMode: InstalmentPlanMode.SmoothPay,
      offPeriodInstalmentAmount: averageWeeklyPayment ?? 0,
      targetDate: undefined,
    });
  };

  useEffect(() => {
    if (!data?.previewInstalmentPlan.length && !loading && !error) {
      fetchPlans({});
    }

    if (!instalmentFrequency) {
      setMode(FREQUENCY_OPTIONS[0].value);
      patch({instalmentFrequency: FREQUENCY_OPTIONS[0].value});
    }
  }, []);

  if (!date) {
    return (
      <BeautifiedErrorMessage
        debbieTitle="Oops, something went wrong!!"
        debbieMessage="Missing start date. Please try again."
        onClick={() => {
          navigateTo('/biller/:slug/setup', {slug: billerSlug});
        }}
      />
    );
  }

  const firstRegularInstalment = data?.previewInstalmentPlan
    ?.find(plan =>
      instalmentFrequency ? instalmentFrequency === plan?.frequency : true
    )
    ?.balancedInstalmentPreview?.find(
      plan => !plan.instalments.find(({type}) => type === InstalmentType.Setup)
    );

  return (
    <div className="relative w-full h-full px-4 py-6 mx-auto sm:px-6 lg:py-16 lg:px-8">
      <div className="relative flex flex-col h-full">
        <Debbie
          title="How would you like to pay your current notice?"
          message={
            firstRegularInstalment?.amount ? (
              <>
                <strong>
                  ${formatToDollars(firstRegularInstalment.amount)}
                </strong>{' '}
                is due on the{' '}
                {firstRegularInstalment.dueAt?.toFormat('dd MMM yyyy')}
              </>
            ) : null
          }
        />

        <div className="grid w-full gap-2 mt-6">
          <div className="space-y-4">
            <h4 className="font-semibold">Start Date</h4>
            <span className="mb-2 text-sm text-gray-600">
              The date your payments will start.
            </span>

            <DatePicker
              error={dateError}
              setError={setDateError}
              minDate={minDate}
              maxDate={maxDate}
              onChange={date => {
                patch({instalmentStartAt: date.toISO()});
                setDate(date);

                fetchPlans({startDate: date});
              }}
              value={date}
            />
          </div>
        </div>

        <div className="flex flex-col items-center justify-center flex-1 h-full max-w-[512px] min-w-[368px]">
          <StyledRadioGroup
            value={mode}
            options={getSmoothOptionsCopy({
              account,
              plans: data?.previewInstalmentPlan,
              offPeriodInstalmentAmount: amountInCents,
              instalmentFrequency,
            })}
            onChange={e => {
              setMode(e as PaymentFrequency);

              patch({
                instalmentStartAt: date.toISO(),
                instalmentFrequency: e,
              });
            }}
          />

          <button
            type="button"
            className="inline-flex items-center justify-center w-full px-6 py-3 mt-4 text-base font-medium text-center text-white transition bg-blue-600 border border-transparent rounded-md shadow-sm disabled:opacity-50 disabled:cursor-not-allowed hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
            disabled={!!dateError || loading}
            onClick={() => {
              if (hasInstalments) {
                navigate('/biller/:slug/setup/smooth/next', {
                  instalmentFrequency: mode,
                  instalmentStartAt: date.toISO(),
                });
              } else {
                navigate('/biller/:slug/setup/smooth/amount', {
                  instalmentStartAt: date.toISO(),
                });
              }
            }}
          >
            Next
          </button>
          <button
            onClick={() => navigate('/biller/:slug/setup/smooth')}
            className="mt-6 text-blue-600 transition hover:text-blue-700"
          >
            Back
          </button>
        </div>
      </div>
    </div>
  );
};
