import {Debbie} from 'components/organisms/Debbie';
import {useSetupRoute} from '../components/SetupRoute';
import {SmoothPayNext} from './SmoothPayNext';
import {useSetupNavigate} from '../hooks/useSetupNavigate';
import {SmoothPayFrequency} from './SmoothPayFrequency';

export const SmoothPaySetup = () => {
  const {account} = useSetupRoute();
  const navigate = useSetupNavigate();
  const hasSetup = !!account.setupBreakdown?.length;
  const hasInstalments = !!account.targetInstalments?.length;

  if (!hasSetup && !hasInstalments) {
    return (
      <div className="relative w-full h-full px-4 py-6 mx-auto sm:px-6 lg:py-16 lg:px-8">
        <div className="relative flex flex-col h-full">
          <Debbie title="Looks like you don't have any amounts outstanding" />
          <div className="mt-4 overflow-hidden rounded-md shadow">
            <div className="px-4 py-4 text-center bg-white sm:p-6">
              <p>Let's skip straight to preparing for your next rates notice</p>
            </div>
          </div>
          <button
            type="button"
            className="inline-flex items-center justify-center w-full px-6 py-3 mt-4 text-base font-medium text-center text-white transition bg-blue-600 border border-transparent rounded-md shadow-sm disabled:opacity-50 disabled:cursor-not-allowed hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
            onClick={() => {
              navigate('/biller/:slug/setup/smooth/next');
            }}
          >
            Next
          </button>
          <button
            onClick={() => navigate('/biller/:slug/setup/smooth')}
            className="mt-6 text-blue-600 transition hover:text-blue-700"
          >
            Back
          </button>
        </div>
      </div>
    );
  }

  if (!hasInstalments) {
    return <SmoothPayNext />;
  }

  return <SmoothPayFrequency />;
};
