import {
  addContactPaymentMethod,
  updateContact,
} from 'features/setup/graphql/actions';
import {Contact, PaymentMethod} from 'lib/graphql/API';
import {err} from 'payble-shared';
import {useCallback, useState} from 'react';

type CardDetails = {
  numberToken: string;
  numberTokenHmac: string;
  expiryMonth: number;
  expiryYear: number;
  brand: string;
  last4: string;
  holderName: string;
  referenceNumber: string;
};

type BankDetails = {
  accountName: string;
  accountNumber: string;
  bsb: string;
};

type ContactDetails = {
  title: string;
  email: string;
  givenName: string;
  familyName: string;
};

type PaymentMethodParams =
  | {
      paymentMethodType: 'card';
      contact: ContactDetails;
      card: CardDetails;
      sendReceipts?: boolean;
    }
  | {
      paymentMethodType: 'direct_debit';
      contact: ContactDetails;
      bank: BankDetails;
      sendReceipts?: boolean;
    }
  | {
      paymentMethodType: 'nz_direct_debit';
      accountName: string;
      accountNumber: string;
      contact: ContactDetails;
      sendReceipts?: boolean;
    };

type AddPaymentMethodToContactResult = {
  paymentMethod: PaymentMethod;
  contact: Contact;
};

export function useAddPaymentMethodToContactMutation() {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [data, setData] = useState<AddPaymentMethodToContactResult>();

  const add = useCallback(async (params: PaymentMethodParams) => {
    setLoading(true);
    setError('');
    try {
      const contact = params.contact;
      const updatedContact = await updateContact({
        email: contact.email,
        title: contact.title,
        familyName: contact.familyName,
        givenName: contact.givenName,
        sendReceipt: params.sendReceipts,
      });

      if (err(updatedContact)) throw updatedContact;

      let paymentMethod: PaymentMethod;
      if (params.paymentMethodType === 'card') {
        const {paymentMethodType, card} = params;
        const result = await addContactPaymentMethod({
          paymentMethodType,
          card,
        });

        if (err(result)) throw result;

        paymentMethod = result.paymentMethod;
      } else if (params.paymentMethodType === 'direct_debit') {
        const {paymentMethodType, bank} = params;

        const result = await addContactPaymentMethod({
          paymentMethodType,
          bank,
        });

        if (err(result)) throw result;

        paymentMethod = result.paymentMethod;
      } else if (params.paymentMethodType === 'nz_direct_debit') {
        const {paymentMethodType, accountName, accountNumber} = params;

        const result = await addContactPaymentMethod({
          paymentMethodType,
          nzBank: {
            accountName,
            accountNumber,
          },
        });

        if (err(result)) throw result;

        paymentMethod = result.paymentMethod;
      } else {
        throw new Error(
          `Unknown payment method type ${(params as any).paymentMethodType}`
        );
      }

      const data = {
        paymentMethod,
        contact: updatedContact,
      };
      setData(data);
      setLoading(false);

      return data;
    } catch (e) {
      setLoading(false);
      setError((e as Error).message);
    }
  }, []);

  return {add, loading, error, data};
}
