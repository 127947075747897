import {
  Account,
  InstalmentType,
  PreviewInstalmentPlanQuery,
} from 'lib/graphql/API';
import {formatToDollars} from 'payble-shared';

type PaymentFrequency = 'weekly' | 'fortnightly' | 'monthly';

type FrequencyOption = {
  id: string;
  value: PaymentFrequency;
  label: string;
  description: string;
};

export const FREQUENCY_OPTIONS: FrequencyOption[] = [
  {
    id: 'plan-frequency-weekly',
    value: 'weekly',
    label: 'Weekly',
    description:
      'Pay an amount weekly to catch up and get on top of your rates',
  },
  {
    id: 'plan-frequency-fortnightly',
    value: 'fortnightly',
    label: 'Fortnightly',
    description:
      'Pay an amount fortnightly to catch up and get on top of your rates',
  },
  {
    id: 'plan-frequency-monthly',
    value: 'monthly',
    label: 'Monthly',
    description:
      'Pay an amount monthly to catch up and get on top of your rates',
  },
];

type GetLabelCopy = {
  pickedPaymentIsSame: boolean;
  option: FrequencyOption;
  planFrequency: string;
  firstInstalmentAmount: number;
  firstRegularBlockInstalmentsLength: number;
  offPeriodInstalmentAmount: number;
  isPickedFrequency: boolean;
};

function getLabelCopy({
  pickedPaymentIsSame,
  option,
  firstInstalmentAmount,
  firstRegularBlockInstalmentsLength,
  offPeriodInstalmentAmount,
  planFrequency,
  isPickedFrequency,
}: GetLabelCopy) {
  const descriptionIfPaymentIsDifferent =
    isPickedFrequency && offPeriodInstalmentAmount
      ? `Then $${formatToDollars(
          offPeriodInstalmentAmount
        )} ${planFrequency} between bills.`
      : `Then your chosen amount ${planFrequency} between bills.`;

  return pickedPaymentIsSame
    ? {
        label: `$${formatToDollars(firstInstalmentAmount)} ${option.label}`,
        description:
          "If you have a bill higher than your payments coming up we'll increase your payments to ensure it is paid off in time.",
      }
    : {
        label: `${
          option.label
        } - ${firstRegularBlockInstalmentsLength} payment${
          firstRegularBlockInstalmentsLength > 1 ? 's' : ''
        } of $${formatToDollars(firstInstalmentAmount)}`,
        description: descriptionIfPaymentIsDifferent,
      };
}

type GetOptionsCopy = {
  account: Account;
  plans?: PreviewInstalmentPlanQuery['previewInstalmentPlan'];
  offPeriodInstalmentAmount: number;
  instalmentFrequency: string;
  frequencyOptions?: FrequencyOption[];
};

export function getSmoothOptionsCopy({
  account,
  plans,
  offPeriodInstalmentAmount,
  instalmentFrequency,
  frequencyOptions = FREQUENCY_OPTIONS,
}: GetOptionsCopy) {
  if (!plans || !account.targetInstalments?.length) {
    return frequencyOptions;
  }

  return frequencyOptions.map(option => {
    const plan = plans.find(plan => plan?.frequency === option.value);

    if (!plan) {
      return option;
    }

    const firstRegularBlock = plan.balancedInstalmentPreview.filter(
      plan => !plan.instalments.find(({type}) => type === InstalmentType.Setup)
    )[0];

    const firstInstalment = firstRegularBlock?.instalments[0];

    const pickedPaymentIsSame =
      firstInstalment.amount === offPeriodInstalmentAmount;

    if (!firstInstalment || !firstRegularBlock.instalments.length) {
      return option;
    }

    const isPickedFrequency = instalmentFrequency === option.value;

    const labelCopy = getLabelCopy({
      pickedPaymentIsSame,
      option,
      firstInstalmentAmount: firstInstalment.amount,
      firstRegularBlockInstalmentsLength: firstRegularBlock.instalments.length,
      offPeriodInstalmentAmount,
      planFrequency: option.label.toLowerCase(),
      isPickedFrequency,
    });

    return {
      ...option,
      ...labelCopy,
    };
  });
}
