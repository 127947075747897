import {PaymentMethodType} from 'payble-shared';
import {PaymentMethodType as GQLPaymentMethodType} from '../graphql/API';

export type Bank = {
  bsb: string;
  name: string;
  icon?: any;
};

export type DirectDebitValues = {
  accountNumber: string;
  bsbNumber: string;
  bank: Bank | null;
};

export type NZDirectDebitValues = {
  accountName: string;
  accountNumber: string;
};

export const fromPaymentMethodType = (
  t: PaymentMethodType
): GQLPaymentMethodType => {
  switch (t) {
    case 'direct_debit':
      return GQLPaymentMethodType.DirectDebit;
    case 'card':
      return GQLPaymentMethodType.Card;
    case 'nz_direct_debit':
      return GQLPaymentMethodType.NzDirectDebit;
    default:
      throw new Error(`Unknown payment method type: ${t}`);
  }
};

export const toPaymentMethodType = (
  t: GQLPaymentMethodType
): PaymentMethodType => {
  switch (t) {
    case GQLPaymentMethodType.DirectDebit:
      return 'direct_debit';
    case GQLPaymentMethodType.Card:
      return 'card';
    case GQLPaymentMethodType.NzDirectDebit:
      return 'nz_direct_debit';
    default:
      throw new Error(`Unknown payment method type: ${t}`);
  }
};
