import React, {Dispatch, useEffect} from 'react';

type CancelReasonProps = {
  reason: string;
  setReason: Dispatch<React.SetStateAction<string>>;
  setCanGoNext: Dispatch<React.SetStateAction<boolean>>;
};

export const CancelReason: React.FC<CancelReasonProps> = ({
  reason,
  setReason,
  setCanGoNext,
}) => {
  useEffect(() => {
    setCanGoNext(false);
  }, []);
  return (
    <>
      {' '}
      <label
        htmlFor="reason"
        className="block text-sm font-semibold text-gray-700 mb-2"
      >
        Please add a reason
      </label>
      <div className="mt-1">
        <textarea
          maxLength={40}
          minLength={2}
          rows={4}
          name="reason"
          id="reason"
          className="shadow-sm focus:ring-blue-500 ring-offset-2 ring-2 focus:border-gray-300 block w-full sm:text-sm border-gray-300 rounded-md"
          defaultValue={reason}
          onChange={e => {
            if (e.target.value.length) {
              setCanGoNext(true);
              setReason(e.target.value);
            } else {
              setCanGoNext(false);
            }
          }}
          placeholder="In a few words please tell us why you're cancelling your plan so we can improve the service"
        />
      </div>
    </>
  );
};
