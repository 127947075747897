import {ApolloError} from '@apollo/client';
import {ButtonSpinner} from 'components/atoms/Spinner';
import {TryAgain} from 'components/molecules/TryAgain';
import {InstalmentPlanFragment, InstalmentPlanMode} from 'lib/graphql/API';
import {navigate} from 'lib/navigation/routes';
import {buttonClasses} from 'lib/styles';
import React from 'react';
import {Link} from 'react-router-dom';
import {Options} from '../pages/Cancel';
import NumberFormat from 'react-number-format';
import {formatToDollars} from 'payble-shared';

type CancelConfirmationProps = {
  instalmentPlan: InstalmentPlanFragment;
  cancelError?: ApolloError;
  cancelLoading: boolean;
  billerSlug: string;
  instalmentPlanId: string;
  canCancel: boolean;
  selectedReason: Options;
  onCancel: () => Promise<void>;
};

export const CancelConfirmation: React.FC<CancelConfirmationProps> = ({
  instalmentPlan,
  cancelError,
  cancelLoading,
  billerSlug,
  instalmentPlanId,
  canCancel,
  selectedReason,
  onCancel,
}) => {
  /**
   * If the instalment mode is auto-pay or flexible pay, then we want to show the next instalment due date,
   * otherwise show the instalment plan target date
   *
   * note: auto-pay and flexible pay have no target date
   */
  const paymentDueDateWarning =
    instalmentPlan.mode === InstalmentPlanMode.PayXOnY ||
    instalmentPlan.mode === InstalmentPlanMode.PayOnY ||
    instalmentPlan.mode === InstalmentPlanMode.PayXEveryZ
      ? instalmentPlan.nextInstalmentDueAt
      : instalmentPlan.targetDate;

  return (
    <>
      <h2 className="text-base font-bold text-left sm:text-lg">
        Acknowledgement and confirmation
      </h2>
      <div className="mt-2 mb-2">
        I agree that I want to cancel my payment plan for assessment number{' '}
        {instalmentPlan.account.externalId}
        <div className="mt-3 mb-3">{instalmentPlan.account.description}</div>
        {instalmentPlan.mode === InstalmentPlanMode.PayEveryX ? (
          <>
            I understand that no further {instalmentPlan.account.type} payments
            will be automatically scheduled
            {selectedReason !== Options.OwnershipChange && (
              <>
                {' '}
                and I will need to arrange payment for the remaining{' '}
                <NumberFormat
                  value={formatToDollars(instalmentPlan.amountDue)}
                  displayType={'text'}
                  thousandSeparator={true}
                  decimalSeparator={'.'}
                  fixedDecimalScale={true}
                  decimalScale={2}
                  prefix={'$'}
                />{' '}
                before {paymentDueDateWarning?.toFormat('dd MMM yyyy')}
              </>
            )}
          </>
        ) : (
          <>
            I understand that by cancelling my payments, no further scheduled
            payments will be made through Payble. Any payments already submitted
            will continue to be processed. Any amounts owing will still need to
            be paid by the due date on your notice.
          </>
        )}
      </div>
      {cancelError && (
        <div>
          <TryAgain
            errorMessage={cancelError.message}
            onClick={() => {
              navigate('/biller/:slug/setup', {slug: billerSlug});
            }}
          />
        </div>
      )}
      <button
        disabled={cancelLoading || !canCancel}
        className={buttonClasses}
        onClick={onCancel}
      >
        {cancelLoading && <ButtonSpinner />}
        Confirm cancellation
      </button>
      <Link
        to={`/biller/${billerSlug}/instalment-plan/${instalmentPlanId}`}
        className="mt-5 text-center"
      >
        No, I have changed my mind
      </Link>
    </>
  );
};
