import {PaymentMethodType, SurchargeRate} from '..';

export interface SurchageCalculatorProps {
  readonly cardSurchargePercent?: number;
  readonly directDebitSurchargePercent?: number;
  readonly nzDirectDebitSurchagePercent?: number;
}

interface Surcharge {
  readonly surchargedAmount: number;
  readonly rate: number;
  readonly transactRate: string;
}

export interface ChargeableAmount {
  readonly total: number;
  readonly originalAmount: number;

  readonly surcharge: Surcharge | null;
}

export class SurchargeCalculator {
  private surcharges: Record<PaymentMethodType, SurchargeRate | undefined>;

  constructor(props: SurchageCalculatorProps) {
    this.surcharges = {
      card: props.cardSurchargePercent
        ? SurchargeRate.fromLegacyDecimal(props.cardSurchargePercent)
        : undefined,
      direct_debit: props.directDebitSurchargePercent
        ? SurchargeRate.fromLegacyDecimal(props.directDebitSurchargePercent)
        : undefined,
      nz_direct_debit: props.nzDirectDebitSurchagePercent
        ? SurchargeRate.fromLegacyDecimal(props.nzDirectDebitSurchagePercent)
        : undefined,
    };
  }

  prettySurchargePercent(type: PaymentMethodType) {
    const rate = this.getSurchargeRate(type);
    if (!rate) {
      return null;
    }
    return rate.toPretty();
  }

  calculateCharge(args: {
    amount: number;
    type: PaymentMethodType;
  }): ChargeableAmount {
    const {amount, type} = args;
    const rate = this.getSurchargeRate(type);

    if (rate == null) {
      return {
        total: amount,
        originalAmount: amount,
        surcharge: null,
      };
    }

    return rate.calculateSurcharge(amount);
  }

  /**
   * Transact expects the surcharge rate to be a string in the format of
   * The highest representable value is 99.9999%.
   * @example a value of "015000" = 1.5% surcharge
   */
  private getTransactSurchargeRate(rate: number): string {
    return rate.toFixed(5).slice(2).padEnd(6, '0');
  }

  getSurchargeRate(type: PaymentMethodType): SurchargeRate | null {
    return this.surcharges[type] ?? null;
  }
}
