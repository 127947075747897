import {classNames} from 'lib/styles';
import {useEffect, useRef, useState} from 'react';

interface Props {
  onChange(value: string): void;
}

type FieldName = 'bank' | 'branch' | 'account' | 'suffix';

const inputClassName =
  '[appearance:textfield] [&::-webkit-outer-spin-button]:appearance-none [&::-webkit-inner-spin-button]:appearance-none block w-full mt-1 placeholder-gray-300 border-gray-300 rounded-md shadow-sm focus:ring-blue-600 focus:border-blue-600 sm:text-sm';

export const MAX_LENGTHS = {
  bank: 2,
  branch: 4,
  account: 7,
  suffix: 2,
} as const;

const formatInput = (value: string, maxLength: number) => {
  return parseInt(value.trim().replace(/\D/g, ''))
    .toString()
    .slice(0, maxLength)
    .padStart(maxLength, '0');
};

export const DirectDebitAccountNumberInputNZ: React.FC<Props> = ({
  onChange,
}) => {
  const [bank, setBank] = useState<string>();
  const [branch, setBranch] = useState<string>();
  const [account, setAccount] = useState<string>();
  const [suffix, setSuffix] = useState<string>();
  const bankRef = useRef<HTMLInputElement>(null);
  const branchRef = useRef<HTMLInputElement>(null);
  const accountRef = useRef<HTMLInputElement>(null);
  const suffixRef = useRef<HTMLInputElement>(null);
  const refs = [bankRef, branchRef, accountRef, suffixRef];
  const touched = useRef<Record<FieldName, boolean>>({
    bank: false,
    branch: false,
    account: false,
    suffix: false,
  });

  useEffect(() => {
    if (Object.values(touched.current).every(Boolean)) {
      onChange(`${bank}-${branch}-${account}-${suffix}`);
    }
  }, [bank, branch, account, suffix, touched]);

  const handleKeyDown =
    (prevInput: React.RefObject<HTMLInputElement>) =>
    (e: React.KeyboardEvent<HTMLInputElement>) => {
      if (e.key === 'Backspace' && e.currentTarget.value.length === 0) {
        prevInput.current?.focus();
      }
    };

  const handleChange =
    (setValue: (value: string) => void, index: number) =>
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const value = e.target.value;

      if (value.length >= e.target.maxLength) {
        const nextInput = refs[index + 1];
        if (nextInput?.current) {
          nextInput.current?.focus?.();
          nextInput.current?.select?.();
        } else {
          refs[index].current?.blur();
          refs[index].current?.focus();
        }
      }

      if (value.length > e.target.maxLength) {
        return;
      }

      setValue(value);
    };

  const handleBlur =
    (setValue: (value: string) => void, touchedKey: FieldName) =>
    (e: React.ChangeEvent<HTMLInputElement>) => {
      setValue(formatInput(e.target.value, e.target.maxLength));
      touched.current[touchedKey] = true;
    };

  return (
    <div className="flex items-center gap-2">
      <input
        ref={bankRef}
        type="number"
        className={classNames(inputClassName, 'w-12')}
        min={0}
        max={99}
        autoComplete="off"
        placeholder={'0'.repeat(MAX_LENGTHS.bank)}
        value={bank}
        maxLength={MAX_LENGTHS.bank}
        onKeyDown={handleKeyDown(bankRef)}
        onChange={handleChange(setBank, 0)}
        onBlur={handleBlur(setBank, 'bank')}
      />
      -
      <input
        ref={branchRef}
        type="number"
        className={classNames(inputClassName, 'w-24')}
        min={0}
        max={9999}
        autoComplete="off"
        placeholder={'0'.repeat(MAX_LENGTHS.branch)}
        value={branch}
        maxLength={MAX_LENGTHS.branch}
        onKeyDown={handleKeyDown(bankRef)}
        onChange={handleChange(setBranch, 1)}
        onBlur={handleBlur(setBranch, 'branch')}
      />
      -
      <input
        ref={accountRef}
        type="number"
        className={classNames(inputClassName, 'w-48')}
        min={0}
        max={99999999}
        autoComplete="off"
        placeholder={'0'.repeat(MAX_LENGTHS.account)}
        value={account}
        maxLength={MAX_LENGTHS.account}
        onKeyDown={handleKeyDown(branchRef)}
        onChange={handleChange(setAccount, 2)}
        onBlur={handleBlur(setAccount, 'account')}
      />
      -
      <input
        ref={suffixRef}
        type="number"
        className={classNames(inputClassName, 'w-24')}
        min={0}
        max={9999}
        autoComplete="off"
        placeholder={'0'.repeat(MAX_LENGTHS.suffix)}
        value={suffix}
        maxLength={MAX_LENGTHS.suffix}
        onKeyDown={handleKeyDown(accountRef)}
        onChange={handleChange(setSuffix, 3)}
        onBlur={handleBlur(setSuffix, 'suffix')}
      />
    </div>
  );
};
