import React from 'react';
import {ApolloProvider} from '@apollo/client';
import * as Sentry from '@sentry/react';

import * as Auth from 'lib/auth/Auth';
import * as BillerConfig from 'lib/appConfig/BillerConfig';
import {client} from 'lib/graphql/client';

import {Navigation} from 'features/navigation/Navigation';

import './tailwind.css';
import {ErrorPage} from 'features/navigation/Error';

import _refiner from 'refiner-js';
import {LivenessWrapper} from './lib/appConfig/LivenessWrapper';
import {VersionPoll} from 'components/organisms/VersionPoll';
import {QueryClient, QueryClientProvider} from '@tanstack/react-query';
_refiner('setProject', '785842d0-f9bf-11ec-8c7c-9962ba52a877');

const queryClient = new QueryClient();

const App: React.FC = () => {
  return (
    <Sentry.ErrorBoundary fallback={ErrorPage}>
      <QueryClientProvider client={queryClient}>
        <BillerConfig.Provider ErrorComponent={ErrorPage}>
          <LivenessWrapper>
            <Auth.Provider>
              <ApolloProvider client={client}>
                <Navigation />
              </ApolloProvider>
            </Auth.Provider>
          </LivenessWrapper>
          <VersionPoll />
        </BillerConfig.Provider>
      </QueryClientProvider>
    </Sentry.ErrorBoundary>
  );
};

export default App;
