import {
  cleanParams,
  isSearchParamsEqual,
  mergeSearchParams,
  searchParamsToObject,
} from 'lib/url';
import {useCallback, useMemo} from 'react';
import {useSearchParams} from 'react-router-dom';

export function usePatchSearchParams<T extends Record<string, string>>(
  initialParams?: T
): {params: T; patch: (newParams: Record<string, string>) => void} {
  const [searchParams, setSearchParams] = useSearchParams(initialParams);

  const params = useMemo(
    () => cleanParams(searchParamsToObject(searchParams)),
    [searchParams]
  ) as T;

  const patch = useCallback(
    (newParams: Record<string, string>) => {
      const nextSearchParams = mergeSearchParams(params, newParams);
      if (!isSearchParamsEqual(new URLSearchParams(params), nextSearchParams)) {
        setSearchParams(nextSearchParams);
      }
    },
    [params, setSearchParams]
  );

  return {
    params,
    patch,
  };
}
